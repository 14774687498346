import React from 'react';
import {
  Box,
  GridItem,
  Heading,
  PageGrid,
  Stack,
  StackItem,
  InputTelephone,
  Checkbox,
  ScrollLinkWrapper,
  RichText,
  Grid,
  PostalCodeCheckForm,
  CTABlock,
} from '@ads-core/components';
import {
  houseNumberPattern,
  postalCodePattern,
  requiredFieldPattern,
  telephonePattern,
} from '@alliander-fe/validation';
import { useTrackingContext, useTrackingFormTouch } from '@liander/context';
import { IntegrationApi } from '@alliander-fe/api';
import { SitecoreImage } from '@alliander-fe/jss-utils';
import { FilteredOutagesSMSServiceProps } from 'components/OutagesSMSService';

import { useForm, SubmitHandler } from 'react-hook-form';

import { useMutation } from '@tanstack/react-query';
import { TelephoneNumberMasking, applyC4Rules, extractString } from '../Form/utils/utils';
import { Message } from './_Message';

type Inputs = {
  houseNumber: number;
  addition: string;
  postalCode: string;
  telephoneNumber: string;
  save: boolean;
};

export const OutagesSMSServiceView = (props: FilteredOutagesSMSServiceProps) => {
  const { trackWizardSimpleChange } = useTrackingContext();
  const [toggleNotification, setToggleNotification] = React.useState<boolean>();
  const trackingFormName = 'aanmelden sms-berichtenservice storingschecker';

  const getServiceMutation = useMutation({
    mutationFn: IntegrationApi.serviceAvailabilityEndpointsGetServiceAvailability,
  });

  const smsMutation = useMutation({
    mutationFn: IntegrationApi.outagesEndpointsSubscribeSmsService,
  });

  const { handleSubmit, formState, register, setValue } = useForm({
    mode: 'onTouched',
  });

  useTrackingFormTouch({
    wizardName: 'sms-berichtenservice storingschecker',
    wizardStep: '1',
    fields: formState.touchedFields,
  });

  const isServiceNotFound =
    getServiceMutation.isSuccess && Boolean(!getServiceMutation.data?.isOperational);
  const isLoading = getServiceMutation.isPending || smsMutation.isPending;
  const hasError = getServiceMutation.isError || smsMutation.isError;
  const isSuccess = getServiceMutation.isSuccess && smsMutation.isSuccess;

  const notification = NotificationMessage({ isServiceNotFound, hasError, isSuccess, props });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    trackWizardSimpleChange({
      action: 'submit',
      actionDetail: 'bevestigen details sms-berichtenservice storingschecker',
      wizardName: trackingFormName,
      wizardStep: '2',
    });

    if (data.save) {
      // Sets the correct format for postalCode and telephoneNumber.
      const postalCode = data.postalCode.replace(/\s+/g, '');
      const telephoneNumber = data.telephoneNumber.replace('+31 6', '06');

      setToggleNotification(true);

      try {
        const result = await getServiceMutation.mutateAsync({
          ...data,
          postalCode,
        });

        if (!result.isOperational) return;
        await smsMutation.mutateAsync({ ...data, postalCode, telephoneNumber });
      } catch (err) {
        return;
      }
    }
  };

  return (
    <ScrollLinkWrapper anchorId="smsdienst">
      <PageGrid>
        <GridItem columnEnd="-1" columnStart="1">
          <CTABlock>
            <CTABlock.Column
              columnStart={'1'}
              columnEnd={{ initial: '-1', md: '8' }}
              variant="main"
              bg="containerPrimary"
            >
              <Stack direction="column" gap={10}>
                <StackItem>
                  {props?.title ? (
                    <Box paddingBottom={{ initial: 3, md: 4 }} asChild>
                      <Heading size="h2" color="onDark">
                        {props.title}
                      </Heading>
                    </Box>
                  ) : null}
                  {props?.text ? <RichText tone="onDark">{props.text}</RichText> : null}
                </StackItem>

                <PostalCodeCheckForm color="none" handleOnSubmit={handleSubmit(onSubmit)}>
                  <Stack gap={4}>
                    <Grid gap={4}>
                      <GridItem columnStart={'1'} columnEnd={{ initial: '-1', md: '7' }}>
                        <Stack isFullWidth>
                          <PostalCodeCheckForm.Input
                            label="Postcode"
                            tone="onDark"
                            placeholder="1234AB"
                            error={extractString(formState?.errors?.postalCode?.message)}
                            {...register('postalCode', {
                              required: requiredFieldPattern('Postcode'),
                              pattern: postalCodePattern,
                            })}
                          />
                        </Stack>
                      </GridItem>

                      <GridItem columnStart={{ initial: '1', md: '7' }} columnEnd={'-1'}>
                        <Stack isFullWidth asChild>
                          <PostalCodeCheckForm.Addendum
                            label={'Huisnr. en toevoeging'}
                            error={extractString(formState?.errors?.houseNumber?.message)}
                            tone="onDark"
                            houseNumber={{
                              label: 'Huisnummer',
                              placeholder: '10',
                              error: extractString(formState?.errors?.houseNumber?.message),
                              ...register('houseNumber', {
                                required: requiredFieldPattern('Huisnummer'),
                                pattern: houseNumberPattern,
                              }),
                            }}
                            addition={{
                              label: 'Toevoeging',
                              placeholder: 'A',
                              ...register('addition'),
                            }}
                          />
                        </Stack>
                      </GridItem>
                    </Grid>

                    <InputTelephone
                      label="Telefoonnummer"
                      tone="onDark"
                      error={extractString(formState?.errors?.telefoonnummer?.message)}
                      handlePhoneMasking={TelephoneNumberMasking}
                      onValueChange={(value, countryCode) =>
                        setValue('telephoneNumber', applyC4Rules(value, countryCode))
                      }
                      {...register('telephoneNumber', {
                        required: requiredFieldPattern('Telefoonnummer'),
                        pattern: telephonePattern,
                      })}
                    />

                    <Box paddingTop={1} paddingBottom={1}>
                      <Checkbox
                        label={
                          props.checkboxLabel?.toString() ??
                          'Bewaar mijn gegevens voor toekomstige storingen'
                        }
                        tone="onDark"
                        onCheckedChange={(checked) => setValue('save', checked)}
                        error={formState?.errors?.save?.message as string | undefined}
                        {...register('save', {
                          required: { value: true, message: 'Dit veld is verplicht' },
                          validate: {
                            validationModel: (v) => {
                              if (typeof v === 'boolean') {
                                return v;
                              }

                              return 'Dit veld is verplicht';
                            },
                          },
                        })}
                      />
                    </Box>

                    <PostalCodeCheckForm.SubmitButton
                      size="large"
                      tone="onDark"
                      variant="secondary"
                      isLoading={isLoading}
                    >
                      {props?.submitButtonLabel
                        ? props?.submitButtonLabel
                        : 'Controleer uw postcode'}
                    </PostalCodeCheckForm.SubmitButton>
                  </Stack>
                </PostalCodeCheckForm>
              </Stack>
            </CTABlock.Column>
            <CTABlock.Column columnStart={{ initial: '1', md: '8' }} columnEnd={'-1'}>
              <CTABlock.ImageContainer
                overlay={
                  toggleNotification && notification ? (
                    <CTABlock.Notification
                      arrowColor="containerPrimary"
                      handleOnClose={() => setToggleNotification(false)}
                    >
                      <Message {...notification} />
                    </CTABlock.Notification>
                  ) : null
                }
              >
                <CTABlock.Image objectFit="cover">
                  <SitecoreImage field={props.image} editable />
                </CTABlock.Image>
              </CTABlock.ImageContainer>
            </CTABlock.Column>
          </CTABlock>
        </GridItem>
      </PageGrid>
    </ScrollLinkWrapper>
  );
};

type NotificationProps = {
  isServiceNotFound?: boolean;
  hasError?: boolean;
  isSuccess?: boolean;
  props: FilteredOutagesSMSServiceProps;
};

const NotificationMessage = ({
  isServiceNotFound,
  hasError,
  isSuccess,
  props,
}: NotificationProps) => {
  switch (true) {
    case isServiceNotFound:
      return {
        text: 'We zien aan uw postcode dat Liander niet uw netbeheerder is. Controleer uw gegevens of zoek uw netbeheerder op via <a href="https://www.mijnaansluiting.nl/netbeheerders" target="_blank">mijnaansluiting.nl</a>.',
      };
    case hasError:
      return {
        title: props.errorMessageTitle?.toString(),
        text: props.errorMessageText?.toString(),
      };
    case isSuccess:
      return {
        title: props.successMessageTitle?.toString(),
        text: props.successMessageText?.toString(),
      };
    default:
      return undefined;
  }
};
