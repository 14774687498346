import { LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import React from 'react';
import { ComponentProps } from 'lib/component-props';
import { ButtonProps } from '@alliander-fe/sitecore-types';
import { OutageHeroComponent } from 'src/components/OutageHero';
import { Box } from '@ads-core/components';
import { renderJssLink } from '@alliander-fe/jss-utils';
import { FilterValuePropertyFromFields, mapWithoutValueProperty } from 'src/utils/mapWithoutValueProperty';

export type OutageTasksProps = {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    title: { value: string };
    color: { value: string };
    link: { value: ButtonProps };
  };
};

type FollowUpStepProps = {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    moreDetailsText: { value: string };
    moreDetailsTitle: { value: string };
    text: { value: string };
    title: { value: string };
    type: { value: string };
    moreDetailsButton: { value: ButtonProps };
    moreDetailsTag: { value: string };
    tag: { value: string };
  };
};

type FollowUpStepFieldProps = {
  followUpSteps: FollowUpStepProps[];
  tag: { value: string };
  title: { value: string };
};

export type OutageFollowUpStepsProps = {
  displayName: string;
  fields: FollowUpStepFieldProps;
  id: string;
  name: string;
  url: string;
};

export type OutageHeroProps = {
  fields?: {
    redirectPageNoOutageFound?: { value?: ButtonProps };
    outageText?: { value?: string };
    noOutageTasks: OutageTasksProps[];
    noOutageText?: { value?: string };
    outageTasks: OutageTasksProps[];
    noOutageLink?: { value?: ButtonProps };
    outagePhaseOneLabel?: { value?: string };
    outagePhaseOneText?: { value?: string };
    outagePhaseTwoLabel?: { value?: string };
    outagePhaseTwoText?: { value?: string };
    outagePhaseThreeLabel?: { value?: string };
    outagePhaseThreeText?: { value?: string };
    outageDetailsModalText?: { value?: string };
    outageDetailsModalLinkOne?: { value?: ButtonProps };
    outageDetailsModalLinkTwo?: { value?: ButtonProps };
    maintenancePhaseOneLabel?: { value?: string };
    maintenancePhaseOneText?: { value?: string };
    maintenancePhaseTwoLabel?: { value?: string };
    maintenancePhaseTwoText?: { value?: string };
    maintenancePhaseThreeLabel?: { value?: string };
    maintenancePhaseThreeText?: { value?: string };
    maintenanceLink?: { value?: string };
    maintenanceText?: { value?: string };
    followUpStepsSectionElectricity?: OutageFollowUpStepsProps;
    followUpStepsSectionGas?: OutageFollowUpStepsProps;

    specificActiveOutageHeroTitle?: { value?: string };
    specificActiveOutageDetailTitle?: { value?: string };
    specificActiveOutageModalTitle?: { value?: string };

    specificActiveMaintenanceHeroTitle?: { value?: string };
    specificActiveMaintenanceDetailTitle?: { value?: string };
    specificActiveMaintenanceModalTitle?: { value?: string };

    specificNoOutageHeroTitle?: { value?: string };

    generalActiveOutageHeroTitle?: { value?: string };
    generalActiveOutageDetailTitle?: { value?: string };
    generalActiveOutageModalTitle?: { value?: string };

    generalActiveMaintenanceHeroTitle?: { value?: string };
    generalActiveMaintenanceDetailTitle?: { value?: string };
    generalActiveMaintenanceModalTitle?: { value?: string };

    generalResolvedOutageHeroTitle?: { value?: string };
    generalResolvedOutageDetailTitle?: { value?: string };
    generalResolvedOutageModalTitle?: { value?: string };

    generalResolvedMaintenanceHeroTitle?: { value?: string };
    generalResolvedMaintenanceDetailTitle?: { value?: string };
    generalResolvedMaintenanceModalTitle?: { value?: string };
  };
} & ComponentProps;

export type FilteredOutageHeroProps = FilterValuePropertyFromFields<OutageHeroProps['fields']>;

const getFilteredTopTasks = (tasks?: OutageTasksProps[]) => {
  if (!tasks) return [];

  const toptasks = tasks.map(({ fields: { link, title, color } }) => {
    const taskText = title.value?.toString();

    const linkField: LinkField = {
      ...link,
      value: {
        ...link.value,
        text: taskText || link.value.text,
      },
    };

    return {
      link: renderJssLink(
        linkField,
        <Box position="relative" zIndex="1" as="span">
          {taskText}
        </Box>
      ),
      arrowLink: renderJssLink(linkField),
      color,
      isAnchor: Boolean(link.value.anchor),
      href: link.value.href,
    };
  });

  type MappedTopTasks = (typeof toptasks)[number];
  type RequiredTopTasks = Omit<MappedTopTasks, 'link'> & {
    link: NonNullable<MappedTopTasks['link']>;
  };

  return toptasks.filter((t): t is RequiredTopTasks => !!t.link);
};

export type TopTasks = ReturnType<typeof getFilteredTopTasks>;

const OutageHero = (props: OutageHeroProps) => {
  if (!props.fields) return null;
  const fields = mapWithoutValueProperty(props.fields);
  const filteredTopTasks = getFilteredTopTasks(props.fields?.outageTasks);
  const filteredNoOutageTopTasks = getFilteredTopTasks(props.fields?.noOutageTasks);
  return <OutageHeroComponent {...fields} topTasks={filteredTopTasks} noOutageTopTasks={filteredNoOutageTopTasks} />;
};

export default withDatasourceCheck()<OutageHeroProps>(OutageHero);
