import "../../libs/ads-core/src/global/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/global/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5WW227bSAyG7/sUAooCW8ARhpyTtHvl+nC56BsUkiUlzqa2I+fgZtF3L6kmXYgzXKA2Ijg/OUMONR9nyi9w3tsDWFP8+64orq7e/oc/i/f4CSuMf810JH07fea6Jb1C2MT1XHekGxPrpZ3rnnS3BXSruR6UeaISt1L0WtEbZf52ytP7GOb6Tsm/U+bvFX1Q9GulzjdKPnsl/1tlnn+Uee6UdX1V8jwo+lHRT4p+r+ijkv9Z8X9Q/B8V/ydFf1bmuZA+eP7O9W+sw86ZYa6/kA5Nu6t3cx2MEhiYsO1y67ZGGFB5ZWB/5WrlGKdF8VqUoCwcorLDgFHbrNbrjUAWmLWwgjpUwsCw+RV4XwtDq9APGm7AvK3rzWorDf00IlTVRhiGn2VcRVmSa62+zFxcf3JWZsXQbfzWrOUCmbq+G2LfCQNj1/shDNLA3A3D4Hqxr4DBG7q+S6Y6aCU5aus4aSMm+JZ1BFkrpm9NI4ysFeH3MDaH86kZ+8ODMGoMwuP/jdJIhGdt41203fJNW+iLUho0Cg4ISnDUDjy0yurRKVVGrxk0FlE79bDS0mUWsY+dRWFgFg1WVQfC0E57OOyMHMEs0jwDilaHncIJMotLa+gjDMSiOV0KMPys+DFet80fNi4KhEVhF4UpDX5cTF4OyhhjoF/Wlg4R8u7gbIzwOgaxtDZYjhHLKtTKEKhputchgKVHCFNaZIqojHl1D2XwhlPxJW0jU+W9TWVirMJbVmUMEcP0C+muoqzE+IBYw0dRNOpT+8O5fyjM9OXB+V1yk3HM7hrqY7zKrjnf9J0Cwu3cJ4cXUndzp4sQ79TTCf+7VCSmg7aRjwrbeNJguVfaBI5aDO1ygVpnw0elUeKTxsSzxgR3tbbr6rYXBu5qza5rBwkkd7Wh7bARdwzLXW2wbS+n4lu8kS+Kr/CYiDbzSvnyXiUidTHD8tufMAc2/8Tq7SE8qKl580GIVS7TOpdUk0uKmhiki6IOlolP7QvTWNS7MA1GjQvTaESmTaMRhjaNRsy5NBpR5tJ5iSufzkBghTQzYiqmORBOVRqNWKrTeU9cs9T5nouWyiNXLZUJoc/j8bL/2hR/H5+aRbEc983dojjT8X917se9ODz44v57Awi5IOHh67xLxOdsUYkzKMWhx9f4zFt5ybg6k3vZDthVtCaHud3ibM7V5bab8zlXIorPQKHGnCtRBGkCNbuK+6Zrcmy4NufKGKW5dq+u338Aua1JE0QQAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/Divider/Divider.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/Divider/Divider.css.ts.vanilla.css\",\"source\":\"LmhlaTRzMCB7CiAgYm9yZGVyOiAwOwogIG1hcmdpbjogMDsKICBoZWlnaHQ6IDFweDsKfQouaGVpNHMxIHsKICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1fMXNpM24xMzJsKTsKfQouaGVpNHMyIHsKICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1fMXNpM24xMzJtKTsKfQouaGVpNHMzIHsKICBiYWNrZ3JvdW5kLWNvbG9yOiBsaW5lYXItZ3JhZGllbnQodG8gcmlnaHQsIHZhcigtLV8xc2kzbjEzMmwpIDEwMCUsIHZhcigtLV8xc2kzbjEzMmwpIDAlKTsKfQ==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/Footer/Footer.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/Footer/Footer.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA41U246bMBR8z1dYkaoF7ZpASLpZovahD33qB1SqqsjACTldx6a2c9lU++81JmQhdylSwD5n5oxnTDCL3rZ/y3kRkn89QlKWvRZKrkSekJXi3sOA5ZpmUsEAl6wAPfiBTOSgZt+lNPbv26Eh+FMWD/7UgmSSS5WQNVMepbNIYyyieOi25BrUnMtNQhaY5yCqtVJqNChFQhRwZnAN1eqOouXZJiSaduaiGneQWA6LNO2994JGQOQEKLmhBSttWxyW20vwrbaha1syVaCgKZfZK4VKfXf6WPidrth1XRFqYGtoDvbkWE0upOgSj9rEKDgKOMuc+XeoGDuwDeZmYaWH4afpkZlGMaFLpkAYtyWVNbGZ6rYSxrGw3Bzm5rJltZaEhK6E5TmKoj7So7VarVtsafhcG4jFwjT1BxqWaslXxtE4Kft19zyXaknGYbjUJFulmNEUdgjKC4PJ8xMJ7S+I4icSdS0c/8qZYVQbZuBLX5Yg+r/JV3I0zoHASpVVqRdNwhyKa1gZlxruAwu7OM+u7OjojvKA9+Rhcgp0Nlx3gb3UYDeKzhxnkoJVC/u7IowNX0L6fWeiLPcmt672PoWqSJk3so6NrHfxS+Vf7Oas4pdYp126P8K+3lTvC6iT06T/SnhqC9yjVQI/PWox/dty5qi0odkCed6W1jBnjGdeRU8eyXBUbv2DUFq9dvDZxTO9ehm7N6gaT5kTU8E/uYBnzYfbkklgxY209eF62WNrv+lxCteoMUWO5u3jk//+HzthERx1BgAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var copyright = '_1yxqpfg4';
export var footerNavLink = '_1yxqpfg3';
export var footerWrapper = '_1yxqpfg0';
export var grid = '_1yxqpfg1';
export var legalNavMobile = '_1yxqpfg8';
export var mobileNavItem = '_1yxqpfg9';
export var mobileNavList = '_1yxqpfga';
export var mobileSocialNav = '_1yxqpfg7';
export var navTitle = '_1yxqpfg2';
export var trigger = '_1yxqpfg5';
export var triggerIcon = '_1yxqpfg6';