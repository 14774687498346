import { GridItem, Heading, PageGrid, RichText, Stack } from '@ads-core/components';
import ErrorNavigation from 'src/components/ErrorNavigation';
import { ErrorTrackingScript } from 'src/components/ErrorTrackingScript';

const Custom404 = (): JSX.Element => {
  return (
    <>
      <ErrorTrackingScript status="404" />
      <ErrorNavigation />
      <PageGrid>
        <GridItem columnStart={{ initial: '1', lg: '3' }} columnEnd={{ initial: '-1', lg: '-3' }}>
          <Stack direction="column" gap={10}>
            <Heading size="h1">Sorry, we kunnen de pagina die u wilt bezoeken niet vinden.</Heading>
            <RichText>
              {`<p>Wat is er aan de hand?
              <ul>
                <li>De pagina bestaat niet meer</li>
                <li>De link waar u op klikte klopt niet</li>
              </ul>
              Gebruik de <a href="/zoeken">zoekmachine</a> om te vinden wat u zoekt. Of kijk
              op onze <a href="/">homepagina</a>.</p>`}
            </RichText>
          </Stack>
        </GridItem>
      </PageGrid>
    </>
  );
};

export default Custom404;
