import React from 'react';
import {
  animate,
  motion,
  useScroll,
  useSpring,
  useTransform,
  useMotionValueEvent,
} from 'framer-motion';
import { Box } from '../Box';
import { GridItem } from '../GridItem';
import { PageGrid } from '../PageGrid';
import { Stack } from '../Stack';
import { ArrowRightIcon } from '../../icons/index';
import { Heading } from '../Heading';
import { Text } from '../Text';
import { TextLink } from '../TextLink';
import { DictionaryDialog } from '../DictionaryDialog';
import { mq } from '../../global/breakpoints';
import { useMedia } from '../../hooks/useMedia';
import { CombineProps } from '../../types/type-helpers';
import * as styles from './HomepageHero.css';

export const HomepageHeroComponent = (props: HomepageHeroProps) => (
  <HomepageHeroComponentView {...props} />
);

export const HomepageHeroSitecore = (props: HomepageHeroSitecoreProps) => (
  <HomepageHeroComponentView {...props} />
);

function useScrollStatus() {
  const [scrollStatus, setScrollStatus] = React.useState<
    'initial' | 'scrolled' | 'returned'
  >('initial');

  const { scrollY } = useScroll();

  useMotionValueEvent(scrollY, 'change', (latest) => {
    if (latest > 10) {
      setScrollStatus('scrolled');
      return;
    }

    if (scrollStatus === 'scrolled') {
      setScrollStatus('returned');
    }
  });

  return scrollStatus;
}

// If you adjust this, don't forget to adjust this in the CSS as well
const insetAmount = 160;

const HomepageHeroComponentView = (props: HomepageHeroViewProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const isDesktop = useMedia(mq.md);
  const scrollStatus = useScrollStatus();

  const base = isDesktop && scrollStatus !== 'initial' ? insetAmount : 0;

  // We need to manually animate the clip-path value
  React.useEffect(() => {
    if (scrollStatus === 'initial' || !isDesktop) return;

    const start = scrollStatus === 'scrolled' ? base : 0;
    const end = scrollStatus === 'scrolled' ? 0 : base;

    animate(start, end, {
      type: 'spring',
      damping: 30,

      onUpdate: (v) => {
        if (!ref.current) return;
        ref.current.style.setProperty('--clipAmount', v + 'px');
      },
    });
  }, [scrollStatus, isDesktop, base]);

  return (
    <div>
      <PageGrid
        style={{ marginTop: `-${base}px` }}
        className="ads-homePageHero"
      >
        {props.text ? (
          <Box asChild paddingBottom={10}>
            <GridItem
              columnStart={{ initial: '1', md: '1' }}
              columnEnd={{
                initial: '-1',
                md: props.explanation ? 'span 7' : '-1',
                xl: props.explanation ? 'span 8' : '-1',
              }}
            >
              <motion.div
                animate={{
                  y: scrollStatus === 'scrolled' && isDesktop ? -300 : 0,
                }}
                transition={{ type: 'spring', damping: 30 }}
              >
                <Heading
                  size="h1"
                  asChild
                  align={{ initial: 'center', md: 'start' }}
                >
                  <h1 style={{ transform: `translateY(${base}px)` }}>
                    <DictionaryDialog>{props.text}</DictionaryDialog>
                  </h1>
                </Heading>
              </motion.div>
            </GridItem>
          </Box>
        ) : null}

        {props.explanation && (
          <LinkBlock
            explanation={props.explanation}
            textLink={props.textLink}
          />
        )}
      </PageGrid>
      {isDesktop ? (
        <Box
          className={styles.ImageWrapper}
          borderRadius={{ md: 'brandXl' }}
          asChild
        >
          <div ref={ref} className={styles.imageBox}>
            <Box asChild className={styles.image}>
              {props.image}
            </Box>
          </div>
        </Box>
      ) : (
        <Box className={styles.ImageWrapper}>
          <div ref={ref} className={styles.imageBox}>
            <Box asChild className={styles.image}>
              {props.image}
            </Box>
          </div>
        </Box>
      )}

      {props.tasks && (
        <PageGrid
          className={styles.TopTaskSection}
          style={{ marginTop: `-${insetAmount}px` }}
        >
          <GridItem columnStart="1" columnEnd="-1">
            {props.tasks}
          </GridItem>
        </PageGrid>
      )}
    </div>
  );
};

type HomepageHeroProps = {
  image?: React.ReactElement;
  text?: string;
  tasks?: React.ReactElement;
  explanation?: string;
  textLink?: React.ReactElement;
};

type HomepageHeroSitecoreProps = {
  text?: React.ReactElement | string;
  image?: React.ReactElement;
  tasks?: React.ReactElement;
  explanation?: React.ReactElement;
  textLink?: React.ReactElement;
};

type HomepageHeroViewProps = CombineProps<
  HomepageHeroProps,
  HomepageHeroSitecoreProps
>;

const LinkBlock = (props: LinkBlockProps) => {
  const { scrollY } = useScroll();
  const isDesktop = useMedia(mq.md);

  const scrollStatus = useScrollStatus();
  const base = isDesktop && scrollStatus !== 'initial' ? insetAmount : 0;

  const margin = useTransform(scrollY, [0, 50], [0, -400]);
  const marginScrollPosition = useSpring(margin, { damping: 50 });

  return (
    <GridItem
      columnStart={{ initial: '1', md: 'span 4' }}
      columnEnd={{ initial: '-1', md: '-1' }}
      className={styles.heroInformationWrapper}
      style={{ transform: `translateY(${base}px)` }}
    >
      <motion.div
        style={{
          width: '100%',
          y: isDesktop ? marginScrollPosition : 0,
        }}
      >
        <Box
          className={styles.rectangleWrapper}
          paddingTop={8}
          paddingBottom={8}
          paddingLeft={6}
          paddingRight={6}
        >
          <Stack alignX="center" alignY="center" gap={6}>
            {props.explanation && (
              <Text
                align="center"
                size="description"
                className={styles.heroInformationText}
              >
                {props.explanation}
              </Text>
            )}
            {props.textLink && (
              <TextLink
                asChild
                afterIcon={<ArrowRightIcon size="xs" />}
                className={styles.TextLink}
                variant="secondary"
              >
                {props.textLink}
              </TextLink>
            )}
          </Stack>
        </Box>
      </motion.div>
    </GridItem>
  );
};

type LinkBlockProps = Pick<HomepageHeroViewProps, 'textLink' | 'explanation'>;
