import { renderToString } from 'react-dom/server';
import parse from 'html-react-parser';
import { LinkField, Link } from '@sitecore-jss/sitecore-jss-nextjs';

export function renderJssLink(
  field: LinkField,
  augmentedChildren?: React.ReactNode
) {
  const isEditor = Boolean(field.editableFirstPart);
  const hasElement = Boolean(field.value.href && field.value.text);

  const element = (
    <Link field={field} editable>
      {isEditor
        ? undefined
        : augmentedChildren
        ? augmentedChildren
        : field.value.text}
    </Link>
  );

  // Note: we render a static link in the editor, because of the weird inner workings of the JSS <Link> component
  return hasElement
    ? isEditor
      ? (parse(renderToString(element)) as React.ReactElement)
      : element
    : undefined;
}
