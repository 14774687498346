import "../../libs/ads-core/src/global/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/global/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5WW227bSAyG7/sUAooCW8ARhpyTtHvl+nC56BsUkiUlzqa2I+fgZtF3L6kmXYgzXKA2Ijg/OUMONR9nyi9w3tsDWFP8+64orq7e/oc/i/f4CSuMf810JH07fea6Jb1C2MT1XHekGxPrpZ3rnnS3BXSruR6UeaISt1L0WtEbZf52ytP7GOb6Tsm/U+bvFX1Q9GulzjdKPnsl/1tlnn+Uee6UdX1V8jwo+lHRT4p+r+ijkv9Z8X9Q/B8V/ydFf1bmuZA+eP7O9W+sw86ZYa6/kA5Nu6t3cx2MEhiYsO1y67ZGGFB5ZWB/5WrlGKdF8VqUoCwcorLDgFHbrNbrjUAWmLWwgjpUwsCw+RV4XwtDq9APGm7AvK3rzWorDf00IlTVRhiGn2VcRVmSa62+zFxcf3JWZsXQbfzWrOUCmbq+G2LfCQNj1/shDNLA3A3D4Hqxr4DBG7q+S6Y6aCU5aus4aSMm+JZ1BFkrpm9NI4ysFeH3MDaH86kZ+8ODMGoMwuP/jdJIhGdt41203fJNW+iLUho0Cg4ISnDUDjy0yurRKVVGrxk0FlE79bDS0mUWsY+dRWFgFg1WVQfC0E57OOyMHMEs0jwDilaHncIJMotLa+gjDMSiOV0KMPys+DFet80fNi4KhEVhF4UpDX5cTF4OyhhjoF/Wlg4R8u7gbIzwOgaxtDZYjhHLKtTKEKhputchgKVHCFNaZIqojHl1D2XwhlPxJW0jU+W9TWVirMJbVmUMEcP0C+muoqzE+IBYw0dRNOpT+8O5fyjM9OXB+V1yk3HM7hrqY7zKrjnf9J0Cwu3cJ4cXUndzp4sQ79TTCf+7VCSmg7aRjwrbeNJguVfaBI5aDO1ygVpnw0elUeKTxsSzxgR3tbbr6rYXBu5qza5rBwkkd7Wh7bARdwzLXW2wbS+n4lu8kS+Kr/CYiDbzSvnyXiUidTHD8tufMAc2/8Tq7SE8qKl580GIVS7TOpdUk0uKmhiki6IOlolP7QvTWNS7MA1GjQvTaESmTaMRhjaNRsy5NBpR5tJ5iSufzkBghTQzYiqmORBOVRqNWKrTeU9cs9T5nouWyiNXLZUJoc/j8bL/2hR/H5+aRbEc983dojjT8X917se9ODz44v57Awi5IOHh67xLxOdsUYkzKMWhx9f4zFt5ybg6k3vZDthVtCaHud3ibM7V5bab8zlXIorPQKHGnCtRBGkCNbuK+6Zrcmy4NufKGKW5dq+u338Aua1JE0QQAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/FollowUpSteps/FollowUpSteps.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/FollowUpSteps/FollowUpSteps.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VXS2+jMBC+91dYlSqBtI4gaZKWXvawxz3uYW8rB5vgLdjIOG3TVf77GvOywSQkUi9RwPP4ZuabGbP4TI8rQgLw7w4ATMsiQ8cIMM7Ii3rxTrFMI7BcP1RPKaH7VEYgDIqP6nnHBSYCSl4okeIDYFSmBAMpECsLJAiTlVTBSyopZxFAu5JnB6kta6VNoOye7hYNhgV5I0wDEa2jgQDHWJ9nJHEdx4dS8pyIHxrIT8qIlm5wxjzjIgJvSHgQ/glLumLhKoz9CyaiHUm4IN/AORmUSCJqZyh+3Qt+YHimQ54XiB2vgby9YMGBeCRyBeCBu6pIjQetH3MmVakjcH9vUKblyARnBML0UEZgHTxMc8RiQUOZQP+rGKYA5FH9N0OSeMrUNwDVr1+JfELKMPlQHoYEMkL/OugmQS8ihzOghxpw70wQpUrftLMciT1lcJfx+BWWEglZoatA9+pnKjbV9dfloY44RlnstYG4gv3tdXG6M9dFH3aZg0s7lq4BzaCu67uwbYmrTFidsBxUxMFcW32F/C4kx4ky3Mb1C+3nAcImoNXtsaxungePNwBd36CzOcP/jq/WOthMN3rP6Mea0u2eswheM9pYbI48nO//oKfwyubwdm44g+4mDI/IszfztJ0TdW34y4MO7Rm0jZhMYZzSDHtqDvvdfjIPqu3ia/SuqRYY5p6iDJWyVjOCHkyzTvxZnyYqJbCkn0Sh29SB2wPHPdyaVK2X9kRsn93pqE3VaQDqNR0lM9TJ/Ku6niZH2JUrVr9EWKM5yYj2hDK6Z5BKkpemnLOBltq6jvi9AbwJAlehZq6mLpfohkGxu2FKx/N0Uq3zPSeYIlDGgqhbJGIYeLniT9dNS9XrNbMW5p3XSLKmWpWKUy9kLc4LsptGqEAYU7aHrkmf+0Mlg7vDLQogCBXokYYFadDlM+uiBZ1cHy98B6x1A2uK++0Ff9taGNwp7FuOFd62iavReKxRmQKDORIxLr0ooaKdBn5jwTVBoJp+NYQJi/0A6gxQlqkrc2sBHSR/uda+WeOL9Qn9YR0u52BMoh5ncxvR5YPqW86glTkAziVkiqTzzE2Malc7GcN1FPSzzYzNU8ulVqV9Y+jgRqf/3jQyErw48zXj88LwQJwe5lz0T3en/yy9ygZ/DwAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var cardRecipe = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{hasConclusion:{false:{initial:'zhy3ee8'}}},compoundVariants:[],defaultVariants:{hasConclusion:false},baseClassName:'zhy3ee7'});
export var cardsWrapper = 'zhy3ee1';
export var companyTag = 'zhy3ee3';
export var conclusionBlock = 'zhy3ee6';
export var counter = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{variant:{company:{initial:'zhy3eea'},customer:{initial:'zhy3eeb'},checked:{initial:'zhy3eec'}},index:{odd:{initial:'zhy3eed'},even:{initial:'zhy3eee'}}},compoundVariants:[],defaultVariants:{variant:'company',index:'even'},baseClassName:'zhy3ee9'});
export var customerTag = 'zhy3ee4';
export var dashedLine = 'zhy3ee0';
export var solvedTag = 'zhy3ee5';
export var tag = 'zhy3ee2';