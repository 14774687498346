import { useRef } from 'react';
import { Box } from '../Box';
import * as styles from './ScrollLinkWrapper.css';
interface ScrollLinkWrapperProps {
  children: React.ReactNode;
  anchorId?: string;
}

export const ScrollLinkWrapper = ({
  children,
  anchorId,
}: ScrollLinkWrapperProps) => {
  const anchorRef = useRef<HTMLDivElement>(null);

  if (!anchorId) return children;
  return (
    <Box
      width="100%"
      position="relative"
      id={anchorId}
      ref={anchorRef}
      className={styles.anchor}
    >
      {children}
    </Box>
  );
};
