import React from 'react';
import { useRouter } from 'next/router';

import { baseQueryParams, forceString, outageFormatTexts } from 'src/utils';
import { useParams } from 'src/hooks/useParams';

import {
  IntegrationApi,
  IntegrationApiFeaturesOutagesContractsDataOutage as OutageProps,
} from '@alliander-fe/api';

import {
  ListHeroView,
  Stack,
  TextLink,
  Box,
  Dialog,
  StackItem,
  ScrollLinkWrapper,
  Pagination,
} from '@ads-core/components';
import { outageStatus } from 'src/utils';
import { ChecklistIcon, ChevronRightIcon, PhoneAndroidIcon } from '@ads-core/icons';
import { useDialogContext, useMedia } from '@ads-core/hooks';
import { mq } from '@ads-core/breakpoints';

import { SitecoreImage } from '@alliander-fe/jss-utils';
import { setReplaceText } from '@alliander-fe/validation';

import { FilteredOutagesOnLocationProps } from 'components/OutagesOnLocation';
import { capitalizeLocationName } from 'src/utils/format';
import { rewriteEnergyType } from 'src/utils';

import { useMutation, useQuery } from '@tanstack/react-query';
import { OutageDialogContent, OutageDialogContentProps } from '../OutageModal';
import OutageBlock from '../OutageBlock';

export const queryParams = baseQueryParams;

type LocationOutagesProps = {
  isEditor?: boolean;
} & FilteredOutagesOnLocationProps;

export const LocationOutages = ({
  outageDetailsModalText,
  outageDetailsModalLinkOne,
  outageDetailsModalLinkTwo,
  outageDetailsModalTitle,
  maintenanceDetailsModalTitle,
  isEditor,
  ...props
}: LocationOutagesProps) => {
  const { isOpen, openDialog } = useDialogContext();
  const router = useRouter();
  const [outageDialogData, setOutageDialogData] = React.useState<OutageDialogContentProps>();

  // get the url query params
  const { city, page } = useParams();

  const showAllResolved = Boolean(props.onlyShowAllResolvedOutages);

  // The limit per page when we show all resolved outages
  const allResolvedLimit = 10;

  /**
   * Flag to only show the [x] amount of most recent results.
   * When both resolvedOutages and allResolvedOutages are true (because contentmanagers made a mistake), resolvedOutages wins
   */
  const showRecentResolved = Boolean(props.onlyShowRecentlyResolvedOutages);
  const recentlyResolvedLimit = 2;

  const limit = showRecentResolved ? recentlyResolvedLimit : allResolvedLimit;
  const pageNumber = Math.max((Number(page) || 0) - 1, 0);

  const path = router.query.path || [];
  const locationParams = city || path[path.length - 1] || null;
  const queryLocation = isEditor ? locationParams ?? '' : locationParams;

  // If showRecentResolved and showAllResolved are both false => fetch unresolved outages
  const fetchResolved = showRecentResolved ? showRecentResolved : showAllResolved;

  /**
   * Query to get the total count of outages on a location for pagination page count
   * This is a separate call because the Liander API is stupid
   */
  const totalCountQuery = useQuery({
    enabled: !!queryLocation && !showRecentResolved,
    queryKey: [queryLocation, showRecentResolved],
    queryFn: () =>
      IntegrationApi.outagesEndpointsGetOutagesOnLocation({
        location: queryLocation,
        Resolved: fetchResolved,
        Amount: 0,
        ReturnCountOnly: true,
      }),
  });

  const { status, data } = useQuery({
    enabled: !!queryLocation,
    queryKey: [queryLocation, showRecentResolved, limit, pageNumber],
    queryFn: () =>
      IntegrationApi.outagesEndpointsGetOutagesOnLocation({
        location: queryLocation,
        Resolved: fetchResolved,
        Amount: limit,
        Offset: pageNumber * limit,
        ReturnCountOnly: false,
      }),
    select: (data) => {
      return {
        ...data,
        outages: data?.outages?.map((outage) => {
          return {
            ...outage,
            energyType: rewriteEnergyType(outage.energyType),
          };
        }),
      };
    },
  });

  const specificOutageMutation = useMutation({
    mutationFn: IntegrationApi.outagesEndpointsGetSpecificOutage,
  });

  React.useEffect(() => {
    if (!isOpen) {
      setOutageDialogData(undefined);
    }
  }, [isOpen]);

  const noResolvedResults = fetchResolved && (data?.count === 0 || !data?.outages);

  if (!isEditor && (!queryLocation || !data || noResolvedResults)) return null;

  const resultCount = data?.count || 0;
  const totalCount = totalCountQuery.data?.count ?? resultCount;
  const location = capitalizeLocationName(queryLocation ?? '');
  const outages = data?.outages;
  const amountOfPages = Math.floor(totalCount / limit);
  const currentPage = pageNumber <= amountOfPages ? pageNumber : amountOfPages;

  const handleOpenDialog = async (props: OutageProps) => {
    if (!props.outageNumber) return;

    const outageModalData = await specificOutageMutation.mutateAsync({
      outageNumber: props.outageNumber.toString(),
    });

    const hasData = Boolean(outageModalData && outageModalData.outageNumber);
    const modalOutageData = hasData ? outageModalData : props;

    const transformedEnergyType = rewriteEnergyType(modalOutageData.energyType);

    const outageData = {
      type: transformedEnergyType || '',
      places: modalOutageData.affectedPlaces || '',
      postalCode: modalOutageData.affectedPostalCodes || '',
    };

    const status = outageStatus(modalOutageData.cause);
    const modalTitleText =
      status === 'maintenance'
        ? maintenanceDetailsModalTitle?.toString()
        : outageDetailsModalTitle?.toString();
    const modalTitle = outageFormatTexts(modalTitleText || '', outageData);

    const dialogData: OutageDialogContentProps = {
      ...modalOutageData,
      title: modalTitle,
      affectedStreets: modalOutageData.affectedStreets?.replace(/;/g, ', ') || '',
      affectedPostalCodes: modalOutageData.affectedPostalCodes?.replace(/;/g, ', ') || '',
      energyType: transformedEnergyType,
      outageDetailsText: outageDetailsModalText?.toString() ?? '',
      outageDetailsLinkOne: outageDetailsModalLinkOne,
      outageDetailsLinkTwo: outageDetailsModalLinkTwo,
    };

    if (hasData) {
      dialogData.statusDescription = '';
      dialogData.postalCode = '';
    }

    setOutageDialogData(dialogData);
    openDialog();
  };

  return (
    <>
      {outageDialogData ? (
        <Dialog>
          <OutageDialogContent {...outageDialogData} />
        </Dialog>
      ) : null}
      <ScrollLinkWrapper
        anchorId={
          showRecentResolved || showAllResolved
            ? 'opgelosteonderbrekingen'
            : 'actueleonderbrekingen'
        }
      >
        <ListHeroView
          image={props.image?.src ? <SitecoreImage field={props.image} editable /> : undefined}
          variant={showRecentResolved ? 'contained' : 'breakout'}
          text={
            status === 'success'
              ? outagesTitle(
                  totalCount,
                  showRecentResolved || showAllResolved ? props?.title?.toString() : undefined,
                  location
                )
              : undefined
          }
          list={
            status === 'success' ? (
              <Box width="100%" overflowY="hidden" asChild>
                <Stack
                  direction={showRecentResolved ? { initial: 'row', md: 'column' } : 'column'}
                  gap={4}
                  isFullWidth
                >
                  {outages?.map((outage, index) => (
                    <Box
                      width={
                        showRecentResolved ? { initial: '90%', sm: '50%', md: '100%' } : '100%'
                      }
                      key={index}
                      asChild
                    >
                      <StackItem shrink={false}>
                        <OutageBlock.Container>
                          {showRecentResolved ? (
                            <OutageBlock.Resolved
                              {...outage}
                              handleToggleDialog={(props) => {
                                handleOpenDialog(props);
                              }}
                            />
                          ) : null}

                          {!showRecentResolved && showAllResolved ? (
                            <OutageBlock.AllResolved
                              {...outage}
                              handleToggleDialog={(props) => handleOpenDialog(props)}
                            />
                          ) : null}

                          {!showRecentResolved && !showAllResolved ? (
                            <OutageBlock.Current
                              {...outage}
                              resolvedOutages={showRecentResolved || showAllResolved}
                              handleToggleDialog={(props) => handleOpenDialog(props)}
                            />
                          ) : null}
                        </OutageBlock.Container>
                      </StackItem>
                    </Box>
                  ))}
                </Stack>
              </Box>
            ) : null
          }
          explanation={
            status === 'success' ? (
              <Stack gap={2} alignY="end">
                <ExplanationLinks
                  showRecentResolved={showRecentResolved}
                  showAllResolved={showAllResolved}
                  hasOutages={Boolean(Number(totalCount))}
                  outageData={props}
                />
              </Stack>
            ) : undefined
          }
        />
      </ScrollLinkWrapper>
      {outages ? (
        <Pagination
          forcePage={currentPage}
          pageCount={totalCount ? totalCount / limit : 0}
          onPageChange={(e) => {
            const selectedPage = e.selected + 1;
            router.push({
              pathname: router.pathname,
              query: {
                ...router.query,
                [queryParams.page]: selectedPage,
              },
            });
          }}
        />
      ) : null}
    </>
  );
};

type ExplanationLinksProps = {
  showRecentResolved?: boolean;
  showAllResolved?: boolean;
  hasOutages: boolean;
  outageData: FilteredOutagesOnLocationProps;
};

const ExplanationLinks = ({
  showRecentResolved,
  showAllResolved,
  hasOutages,
  outageData,
}: ExplanationLinksProps) => {
  const isDesktop = useMedia(mq.md);
  const router = useRouter();
  const path = router.query.path || [];
  const queryLocation = forceString(router.query.plaats) || path[path.length - 1] || null;
  const location = capitalizeLocationName(queryLocation ?? '');

  const links: Array<{
    href: string;
    text: string;
    afterIcon?: React.ReactElement;
    beforeIcon?: React.ReactElement;
  }> = [];

  // Maps the icons to all the different states
  const noOutagesIcon = !hasOutages ? <ChevronRightIcon size="sm" /> : undefined;
  const allResolvedIcon =
    !showRecentResolved && showAllResolved ? <ChevronRightIcon size="sm" /> : undefined;
  const recentResolvedDesktopIcon =
    showRecentResolved && isDesktop ? <ChevronRightIcon size="sm" /> : undefined;
  const recentResolvedMobileIcon =
    showRecentResolved && !isDesktop ? <ChevronRightIcon size="sm" /> : undefined;
  const unresolvedLinkIconOne =
    !showRecentResolved && !showAllResolved ? <PhoneAndroidIcon size="sm" /> : undefined;
  const unresolvedLinkIconTwo =
    !showRecentResolved && !showAllResolved ? <ChecklistIcon size="sm" /> : undefined;

  // Maps the link in case of there are outages
  const linkOne = !hasOutages ? outageData.noOutagesLinkOne : outageData.linkOne;
  const linkTwo = !hasOutages ? outageData.noOutagesLinkTwo : outageData.linkTwo;

  // sets te afterIcon.
  const afterIcon = noOutagesIcon ?? allResolvedIcon ?? recentResolvedDesktopIcon;

  // Sets the links if it as a text and a href
  if (linkOne?.href && linkOne?.text) {
    links.push({
      href: linkOne.href,
      text: linkOne.text,
      afterIcon: afterIcon,
      beforeIcon: noOutagesIcon ? undefined : recentResolvedMobileIcon ?? unresolvedLinkIconOne,
    });
  }

  if (linkTwo?.href && linkTwo?.text) {
    links.push({
      href: linkTwo.href,
      text: linkTwo.text,
      afterIcon: afterIcon,
      beforeIcon: noOutagesIcon ? undefined : recentResolvedMobileIcon ?? unresolvedLinkIconTwo,
    });
  }

  return links.map((link, index) => (
    <TextLink
      href={setReplaceText(link.href, { '{places}': location })}
      key={`link-${index}`}
      afterIcon={link.afterIcon}
      beforeIcon={link.beforeIcon}
    >
      {setReplaceText(link.text, { '{places}': location })}
    </TextLink>
  ));
};

// Create the replace text for the outages based on the maintenance and outages.
const outagesTitle = (
  outages: number | undefined | null,
  resolvedTitle: string | undefined,
  places: string | undefined
): string => {
  if (resolvedTitle)
    return setReplaceText(resolvedTitle, { '{places}': places ? places : '' }) ?? '';

  switch (outages) {
    case 1:
      return `Er is ${outages} gas- of stroomonderbreking in ${places}`;
    case 0:
      return `Er is geen gas- of stroomonderbreking in ${places}`;
    default:
      return `Er zijn ${outages} gas- of stroomonderbrekingen in ${places}`;
  }
};
