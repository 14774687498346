import "../../libs/ads-core/src/global/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/global/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5WW227bSAyG7/sUAooCW8ARhpyTtHvl+nC56BsUkiUlzqa2I+fgZtF3L6kmXYgzXKA2Ijg/OUMONR9nyi9w3tsDWFP8+64orq7e/oc/i/f4CSuMf810JH07fea6Jb1C2MT1XHekGxPrpZ3rnnS3BXSruR6UeaISt1L0WtEbZf52ytP7GOb6Tsm/U+bvFX1Q9GulzjdKPnsl/1tlnn+Uee6UdX1V8jwo+lHRT4p+r+ijkv9Z8X9Q/B8V/ydFf1bmuZA+eP7O9W+sw86ZYa6/kA5Nu6t3cx2MEhiYsO1y67ZGGFB5ZWB/5WrlGKdF8VqUoCwcorLDgFHbrNbrjUAWmLWwgjpUwsCw+RV4XwtDq9APGm7AvK3rzWorDf00IlTVRhiGn2VcRVmSa62+zFxcf3JWZsXQbfzWrOUCmbq+G2LfCQNj1/shDNLA3A3D4Hqxr4DBG7q+S6Y6aCU5aus4aSMm+JZ1BFkrpm9NI4ysFeH3MDaH86kZ+8ODMGoMwuP/jdJIhGdt41203fJNW+iLUho0Cg4ISnDUDjy0yurRKVVGrxk0FlE79bDS0mUWsY+dRWFgFg1WVQfC0E57OOyMHMEs0jwDilaHncIJMotLa+gjDMSiOV0KMPys+DFet80fNi4KhEVhF4UpDX5cTF4OyhhjoF/Wlg4R8u7gbIzwOgaxtDZYjhHLKtTKEKhputchgKVHCFNaZIqojHl1D2XwhlPxJW0jU+W9TWVirMJbVmUMEcP0C+muoqzE+IBYw0dRNOpT+8O5fyjM9OXB+V1yk3HM7hrqY7zKrjnf9J0Cwu3cJ4cXUndzp4sQ79TTCf+7VCSmg7aRjwrbeNJguVfaBI5aDO1ygVpnw0elUeKTxsSzxgR3tbbr6rYXBu5qza5rBwkkd7Wh7bARdwzLXW2wbS+n4lu8kS+Kr/CYiDbzSvnyXiUidTHD8tufMAc2/8Tq7SE8qKl580GIVS7TOpdUk0uKmhiki6IOlolP7QvTWNS7MA1GjQvTaESmTaMRhjaNRsy5NBpR5tJ5iSufzkBghTQzYiqmORBOVRqNWKrTeU9cs9T5nouWyiNXLZUJoc/j8bL/2hR/H5+aRbEc983dojjT8X917se9ODz44v57Awi5IOHh67xLxOdsUYkzKMWhx9f4zFt5ybg6k3vZDthVtCaHud3ibM7V5bab8zlXIorPQKHGnCtRBGkCNbuK+6Zrcmy4NufKGKW5dq+u338Aua1JE0QQAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/TopTasks/TopTasks.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/TopTasks/TopTasks.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA52UUU+DMBDH3/0UjYnJfOgClG3aveybmFKO0chabMtEzb67FBgp21CUhATu7v+767XX3St8ZJodwKC3tdhXqwB93SGUaXVoPxAqILMU4TAIHraN4dS8Vo18QWc/3e0uYeFtWDAB8pLcgEVt7IXEL2sqzw0WuV3YnEUuO8Kq9SaMv+61qmSKuSqUpujI9ALjl9AIIkMSJo9bT0OlzTHPRZEuosd5ADYFIDMBz1OAeCZgMwbk6gj6H4tfz9PsR5p/NCybAsxtGEwB5jaMjwF/aNho8ZtWcxASv4vU5hSRdVzWXsBTd4gLqHHCjDAUrdzxHfzPV35ClqR5/CB2FRStfH/yi593Uwm1xSlwpZkVSlIklQQ3MaUyorNoKBrfEUbaLjsrCooqacA6Ca+0cb0plZAW9Die0gQypaHVcdUEyGY47+/HuVhiVFHZtgKryn56vUHuO3qe9xzEPvfm/3qjrGbSlEw36VzAJxYyhbqlDeWlQ6sakmfPPDtx9t0BUsGQ4RpAIiZTtPC2OYzjoKy7s9YT4v4qGtjux0nOhUdOMtx2P+I366cLOvR0H3juxDln1MO/Af44OP4wBgAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var TopTaskLink = 'q6igu5c';
export var TopTasks = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{color:{purple:{initial:'q6igu55'},blue:{initial:'q6igu56'}}},compoundVariants:[],defaultVariants:{color:'purple'},baseClassName:'q6igu54'});
export var arrowHover = 'q6igu50';
export var arrowHoverOut = 'q6igu51';
export var arrowWhiteHover = 'q6igu52';
export var arrowWhiteHoverOut = 'q6igu53';
export var task = 'q6igu5b';
export var taskBlock = 'q6igu5f';
export var tasksWrapper = 'q6igu5d';
export var variableBlockMobile = 'q6igu5e';
export var variableBlockTask = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{},variantClassNames:{items:{'2':{initial:'q6igu58'},'3':{initial:'q6igu59'},'4':{initial:'q6igu5a'}}},compoundVariants:[],defaultVariants:{},baseClassName:'q6igu57'});