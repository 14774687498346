import React from 'react';
import {
  RichTextField,
  Text,
  TextField,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';

import { SimpleHeroProps } from '@ads-core/types';
import { SimpleHeroSitecore, RichText } from '@ads-core/components';
import { ComponentProps } from 'src/jss/lib/component-props';
import { SitecoreRichText } from '@alliander-fe/jss-utils';

type SimpleHeroSitecoreProps = ComponentProps & {
  fields: {
    tag: TextField;
    text: TextField;
    explanation: RichTextField;
  };
  params: {
    alignment: 'centered' | 'centeredLeft' | 'fillout';
  };
};

type AlignMap = Record<
  SimpleHeroSitecoreProps['params']['alignment'],
  SimpleHeroProps['alignment']
>;

const alignmentMap: AlignMap = {
  centered: 'centered',
  centeredLeft: 'centeredLeft',
  fillout: 'justified',
};

const SimpleHero = (props: SimpleHeroSitecoreProps) => {
  const mappedAlignment = alignmentMap?.[props.params?.alignment];

  const text = props.fields.explanation.editable ? (
    <SitecoreRichText field={props.fields.explanation} editable />
  ) : (
    <RichText>{props.fields.explanation.value ?? undefined}</RichText>
  );

  return (
    <SimpleHeroSitecore
      tag={props.fields.tag.value ? <Text field={props.fields.tag} editable /> : null}
      title={<Text field={props.fields.text} editable />}
      text={text}
      alignment={mappedAlignment}
    />
  );
};

export default withDatasourceCheck()<ComponentProps>(SimpleHero);
