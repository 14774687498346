import React from 'react';
import type { SitecoreForm } from '@sitecore-jss/sitecore-jss-forms';
import { Stack, PageGrid, GridItem, Box } from '@ads-core/components';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';

import { useAdsContext } from '@ads-core/providers';

import { ComponentProps } from 'src/jss/lib/component-props';
import 'src/components/Form/styles/Form.css';
import { Form } from 'src/components/Form';
import { scrollToElement } from '@ads-core/utils';

const FormComponent = ({ fields }: { fields: SitecoreForm } & ComponentProps) => {
  const refPageGrid = React.useRef<HTMLDivElement>(null);
  const adsContext = useAdsContext();

  return (
    <PageGrid ref={refPageGrid}>
      <GridItem columnEnd="-1" columnStart="1" rowStart="1" rowEnd="-1" asChild>
        <Box bg="backgroundLight" borderRadius="lg" paddingTop={20} paddingBottom={20} />
      </GridItem>
      {fields ? (
        <GridItem
          columnEnd={{ initial: '-1', md: '-3' }}
          columnStart={{ initial: '1', md: '3' }}
          rowStart="1"
          rowEnd="-1"
        >
          <Box
            paddingTop={{ initial: 8, md: 20 }}
            paddingBottom={{ initial: 8, md: 20 }}
            paddingLeft={{ initial: 8, md: 0 }}
            paddingRight={{ initial: 8, md: 0 }}
            width="100%"
          >
            <Stack gap={6} isFullWidth>
              <Form
                form={fields}
                handleOnSubmit={(status) => {
                  if (status === 'nextForm' || status === 'success') {
                    scrollToElement(refPageGrid, adsContext.topHeaderHeight + 24);
                  }
                }}
              />
            </Stack>
          </Box>
        </GridItem>
      ) : null}
    </PageGrid>
  );
};

export default withDatasourceCheck()<ComponentProps>(FormComponent);
