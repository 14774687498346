/**
 * These are the rules for determining the status of the outage.
 * @param cause The cause of the outage
 * @returns The status of the outage
 */
export function outageStatus(cause: string | null | undefined) {
  switch (cause) {
    case null:
    case undefined:
      return 'noOutage';
    case 'Spontane storing':
      return 'outage';
    default:
      return 'maintenance';
  }
}
