import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  ColoredSection,
  CookieBotBanner,
  HeaderWrapper,
  PageWrapper,
  Stack,
  StackItem,
} from '@ads-core/components';
import { useAdsContext } from '@ads-core/providers';
import { useRouter } from 'next/router';
import { COOKIEBOT_CBID } from '../../data/constants';
import { LayoutProps } from '../../pages/_app';

// Prefix public assets with a public URL to enable compatibility with Sitecore editors.
// If you're not supporting Sitecore s, you can remove this.
const publicUrl = process.env.PUBLIC_URL ?? '';

const Layout = ({ layoutData }: LayoutProps): JSX.Element => {
  const { route, context } = layoutData.sitecore;
  const fields = route.fields;
  const alternateLinks = context.alternateLinks;

  const canonicalUrl = alternateLinks?.find((link) => {
    return link.language === context.language;
  })?.url;

  const metaTitle = context.metaTitle + ' | Liander';
  const metaKeywords = context.metaKeywords;
  const metaDescription = context.metaDescription;
  const pageType = context.pageType;
  const socialImage = context.image ? context.image.src : `/assets/logo.png}`;
  const metaTag = context.tag;

  const corporateContactStructuredData = context.structuredData?.corporateContact;

  const fallbackRobots = 'index follow';
  const robots = context.Robots?.name ? GetRobotsFormat(context.Robots.name) : fallbackRobots;

  const breadcrumbStructuredData = context.structuredData?.breadcrumb;

  const adsContext = useAdsContext();
  const router = useRouter();

  const [consentString, setConsentString] = useState<string>('');
  const [consentStatus, setConsentStatus] = useState<string>('');

  const reload = () => {
    window.Cookiebot?.changed && window.location.reload();
  };

  useEffect(() => {
    const consent = window.Cookiebot?.consent;
    const marketingCookiesAccepted = consent?.marketing ? consent.marketing : false;

    const allCookiesAccepted = consent
      ? Object.keys(consent).filter((key) => consent[key] === false).length === 0
      : false;

    adsContext.setMarketingCookiesAccepted(marketingCookiesAccepted);
    adsContext.setAllCookiesAccepted(allCookiesAccepted);

    if (consent?.method === null) {
      setConsentStatus('niet gekozen');
    }

    if (window.Cookiebot?.declined === true) {
      setConsentStatus('niet geaccepteerd');
    }

    if (window.Cookiebot?.consented === true) {
      setConsentStatus('geaccepteerd');
    }

    if (consent) {
      const acceptedCookies = Object.keys(consent).filter((key) => consent[key] === true);
      const newJoinedConsentString = acceptedCookies.join('|');
      setConsentString(newJoinedConsentString);
    }

    window.addEventListener('CookiebotOnConsentReady', reload);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', reload);
    };
  }, [adsContext]);

  const browserLanguage =
    typeof window !== 'undefined' ? window.navigator.language : 'not available';

  return (
    <>
      <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.digitalData = {
                page: {
                  pageInfo: {
                    pageName: "${context.metaTitle}",
                    pagePath: "${context.itemPath}",
                    status: 200,
                    issueDate: "not available",
                    version: "not available",
                    languageCode: "${route.itemLanguage}",
                    pageFormat: "not available",
                  },
                  category: {
                    pageCategory: "not available",
                    pageType: "${pageType}",
                    siteSection: "not available",
                  },
                },
                user: {
                  userInfo: {
                    userId: "not available",
                    userType: "not available",
                    loginStatus: "not available",
                    consentStatus: "${consentStatus}",
                    consentType: "${consentString}",
                    browserLanguage: "${browserLanguage}",
                  }
                }
              }
            `,
          }}
        />

        {/* Canonical tag */}
        {canonicalUrl ? (
          <link rel="canonical" href={`${publicUrl}${canonicalUrl}`} key="canonical" />
        ) : null}

        {/* Alternate links. Output them only if there are more than one link */}
        {alternateLinks &&
          alternateLinks.length > 1 &&
          alternateLinks.map((item) => {
            return (
              <link
                rel="alternate"
                href={`${publicUrl}${item.url}`}
                hrefLang={item.language}
                key={item.language}
              />
            );
          })}

        {/* Manageable meta-tags */}
        <title>{metaTitle || fields?.pageTitle?.value.toString() || 'Page'}</title>
        <meta
          property="og:title"
          content={metaTitle || fields?.pageTitle?.value.toString() || 'Page'}
          key="og:title"
        />
        {metaKeywords ? <meta name="keywords" content={metaKeywords} key="keywords" /> : null}
        {metaTag ? <meta name="tag" content={metaTag} /> : null}
        {metaDescription ? (
          <meta name="description" content={metaDescription} key="description" />
        ) : null}

        {metaDescription ? (
          <meta property="og:description" content={metaDescription} key="og:description" />
        ) : null}

        {pageType ? (
          <meta property="og:type" content={pageType} key="og:type" />
        ) : (
          <meta
            property="og:type"
            content={`${publicUrl}${canonicalUrl}`.includes('nieuws') ? 'nieuws' : 'generiek'}
            key="og:type"
          />
        )}

        <meta property="og:image" content={socialImage} key="og:image" />
        <meta property="og:url" content={`${publicUrl}${router.asPath}`} key="og:url" />
        {robots ? <meta name="robots" content={robots} key="robots" /> : null}

        <link rel="icon" href={`${publicUrl}/assets/favicon.png`} key="icon" />
        <CookieBotBanner cbid={COOKIEBOT_CBID} />
      </Head>

      {/* Structured data */}
      {corporateContactStructuredData ? (
        <Script
          strategy="beforeInteractive"
          type="application/ld+json"
          id="corporateContactStructuredData"
          key="structuredata"
        >
          {JSON.stringify(corporateContactStructuredData).replace('[hostName]', publicUrl)}
        </Script>
      ) : (
        <></>
      )}
      {breadcrumbStructuredData ? (
        <Script
          strategy="beforeInteractive"
          type="application/ld+json"
          id="breadcrumbStructuredData"
          key="breadcrumb"
        >
          {JSON.stringify(breadcrumbStructuredData).replace('[hostName]', publicUrl)}
        </Script>
      ) : (
        <></>
      )}

      {/* root placeholder for the app, which we add components to using route data */}
      <PageWrapper gap={10}>
        {route && (
          <HeaderWrapper>
            <Placeholder name="jss-header" rendering={route} />
          </HeaderWrapper>
        )}
        <StackItem grow asChild>
          <main id="top-of-content">
            <Stack gap={20}>
              {route && <Placeholder name="jss-main" rendering={route} />}
              {route && (
                <ColoredSection color="inspiration">
                  <Stack gap={10}>
                    <Placeholder name="jss-separation" rendering={route} />
                  </Stack>
                </ColoredSection>
              )}
            </Stack>
          </main>
        </StackItem>
        {route && <Placeholder name="jss-footer" rendering={route} />}
      </PageWrapper>
    </>
  );
};

function GetRobotsFormat(robotsText: string) {
  if (robotsText === 'NOINDEXNOFOLLOW') return 'noindex nofollow';
  else if (robotsText === 'INDEXNOFOLLOW') return 'index, nofollow';
  else if (robotsText === 'NOINDEXFOLLOW') return 'noindex, follow';
  else return 'index, follow';
}

export default Layout;
