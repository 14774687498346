import "../../libs/ads-core/src/global/theme.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/global/theme.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5WW227bSAyG7/sUAooCW8ARhpyTtHvl+nC56BsUkiUlzqa2I+fgZtF3L6kmXYgzXKA2Ijg/OUMONR9nyi9w3tsDWFP8+64orq7e/oc/i/f4CSuMf810JH07fea6Jb1C2MT1XHekGxPrpZ3rnnS3BXSruR6UeaISt1L0WtEbZf52ytP7GOb6Tsm/U+bvFX1Q9GulzjdKPnsl/1tlnn+Uee6UdX1V8jwo+lHRT4p+r+ijkv9Z8X9Q/B8V/ydFf1bmuZA+eP7O9W+sw86ZYa6/kA5Nu6t3cx2MEhiYsO1y67ZGGFB5ZWB/5WrlGKdF8VqUoCwcorLDgFHbrNbrjUAWmLWwgjpUwsCw+RV4XwtDq9APGm7AvK3rzWorDf00IlTVRhiGn2VcRVmSa62+zFxcf3JWZsXQbfzWrOUCmbq+G2LfCQNj1/shDNLA3A3D4Hqxr4DBG7q+S6Y6aCU5aus4aSMm+JZ1BFkrpm9NI4ysFeH3MDaH86kZ+8ODMGoMwuP/jdJIhGdt41203fJNW+iLUho0Cg4ISnDUDjy0yurRKVVGrxk0FlE79bDS0mUWsY+dRWFgFg1WVQfC0E57OOyMHMEs0jwDilaHncIJMotLa+gjDMSiOV0KMPys+DFet80fNi4KhEVhF4UpDX5cTF4OyhhjoF/Wlg4R8u7gbIzwOgaxtDZYjhHLKtTKEKhputchgKVHCFNaZIqojHl1D2XwhlPxJW0jU+W9TWVirMJbVmUMEcP0C+muoqzE+IBYw0dRNOpT+8O5fyjM9OXB+V1yk3HM7hrqY7zKrjnf9J0Cwu3cJ4cXUndzp4sQ79TTCf+7VCSmg7aRjwrbeNJguVfaBI5aDO1ygVpnw0elUeKTxsSzxgR3tbbr6rYXBu5qza5rBwkkd7Wh7bARdwzLXW2wbS+n4lu8kS+Kr/CYiDbzSvnyXiUidTHD8tufMAc2/8Tq7SE8qKl580GIVS7TOpdUk0uKmhiki6IOlolP7QvTWNS7MA1GjQvTaESmTaMRhjaNRsy5NBpR5tJ5iSufzkBghTQzYiqmORBOVRqNWKrTeU9cs9T5nouWyiNXLZUJoc/j8bL/2hR/H5+aRbEc983dojjT8X917se9ODz44v57Awi5IOHh67xLxOdsUYkzKMWhx9f4zFt5ybg6k3vZDthVtCaHud3ibM7V5bab8zlXIorPQKHGnCtRBGkCNbuK+6Zrcmy4NufKGKW5dq+u338Aua1JE0QQAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../../libs/ads-core/src/components/AnimatedArrow/AnimatedArrow.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/ads-core/src/components/AnimatedArrow/AnimatedArrow.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA72VW4+iMBSA3/0VfdlEEzFcHEcx2cw/mRR6kK7QMm1x3Gzmvy8U5SaXgTX7YAL29DtfD6ft2xl+BwLHING79QvCQ8Id9GeBUCB4rB8QiiBQLjJezB/H7P0r+yneGCpHvhZvj7xtN68fZw3zXqbyjGHerptnzV3voc1TPOkpnx4ZpuFOWi9spHbeNNpI5fxOWmfh2ivd3BmmDki4pIpy5iLsSR6lCo6L5iQlMJMBF7FbPEZYwTL3W2vL1bEOfdVQn0dcuOiCxdIw3i1JHWY51iqnNRsDMxrjIn3VZI5pxhIBlmBQZvBUNTLs6xkoC0FQVYH7ubsRru0yrpYbnzMleBQBWbkhv4BAP1EZY61RBq+m1KI32Ff0Ao1obeph/3wSPGXE6C7Lh66LxwWBbND8nlRV8HGl1sfpqI3TVZtGSSc57Sc47fuctkNOVluK/HPfeSP9ATP7zp/bd9XaJpST9JXz8NxPDGv0fSnok8KTvnFQ7Ce9UQyBCU1lWfjqGBOQHVBZ+vzf3DmI+KeLQkoIsGPnftSnWoIFMFWedzdYOxiZG0fmQUEEV0OGgrJze8+exlpx9EQwGyeClVxRdjJT0gGrpQ3/U1q7mZb2p7Xn8CLN+6REhS5y7OSaM0Kgp1Dd32vRcT16azaji/fs/oyBUIykLwBY1oYELeOs2W6zXnf75LrSnBLLbndnS6MtUty05SzenHXXaQvdLvVBKcu0tw9WySyrjydabfPYlpWYZSWfaHWwH63ULKt0gtVfzAgg7cALAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _a9580 } from 'responsive-recipes';
export var AnimatedArrow = _a9580({initialCondition:'initial',conditions:{initial:{},sm:{'@media':'screen and (min-width: 768px)'},md:{'@media':'screen and (min-width: 1024px)'},lg:{'@media':'screen and (min-width: 1440px)'},xl:{'@media':'screen and (min-width: 1920px)'}},responsiveVariantClassNames:{size:{medium:{initial:'_1jeh9pol',sm:'_1jeh9pon',md:'_1jeh9pop',lg:'_1jeh9por',xl:'_1jeh9pot'},large:{initial:'_1jeh9pom',sm:'_1jeh9poo',md:'_1jeh9poq',lg:'_1jeh9pos',xl:'_1jeh9pou'}}},variantClassNames:{color:{onLight:{initial:'_1jeh9pog'},onDark:{initial:'_1jeh9poh'},ghostOnDark:{initial:'_1jeh9poi'}},direction:{leftToRight:{initial:'_1jeh9poj'},topToBottom:{initial:'_1jeh9pok'}}},compoundVariants:[],defaultVariants:{direction:'leftToRight',size:'medium',color:'onLight'},baseClassName:'_1jeh9pof'});
export var arrowWrap = '_1jeh9po2';
export var flyBase = '_1jeh9po0';
export var flyIn = '_1jeh9po7';
export var flyInAnimation = '_1jeh9po5';
export var flyInAnimationHover = '_1jeh9po3';
export var flyInTopToBottom = '_1jeh9pod';
export var flyInTopToBottomAnimation = '_1jeh9pob';
export var flyInTopToBottomAnimationHover = '_1jeh9po9';
export var flyOut = '_1jeh9po8';
export var flyOutAnimation = '_1jeh9po6';
export var flyOutAnimationHover = '_1jeh9po4';
export var flyOutTopToBottom = '_1jeh9poe';
export var flyOutTopToBottomAnimation = '_1jeh9poc';
export var flyOutTopToBottomAnimationHover = '_1jeh9poa';
export var innerWrap = '_1jeh9po1';