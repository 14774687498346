import { useState } from 'react';
import { Box, Button, ButtonLink, Stack } from '@ads-core/components';
import { CloseIcon } from '@ads-core/icons';
import * as styles from './EditPageBanner.css';

type EditPageBannerProps = {
  firstLink?: React.ReactElement;
  secondLink?: React.ReactElement;
  thirdLink?: React.ReactElement;
};

export const EditPageBanner = ({ firstLink, secondLink, thirdLink }: EditPageBannerProps) => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) {
    return null;
  }

  return (
    <Box asChild bg="backgroundDark" position="relative" paddingTop={4} paddingBottom={4}>
      <Stack direction="row" gap={4} alignX="center">
        {firstLink && (
          <ButtonLink asChild variant="ghostOnDark" size="small">
            {firstLink}
          </ButtonLink>
        )}
        {secondLink && (
          <ButtonLink asChild variant="ghostOnDark" size="small">
            {secondLink}
          </ButtonLink>
        )}
        {thirdLink && (
          <ButtonLink asChild variant="ghostOnDark" size="small">
            {thirdLink}
          </ButtonLink>
        )}
        <Button onClick={() => setIsVisible(false)} variant="ghostOnDark" tone="onDark" size="small" className={styles.closeButton} beforeIcon={<CloseIcon />}>
          Sluiten
        </Button>
      </Stack>
    </Box>
  );
};
